<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

export default {
  extends: Form,
  components: {

  },
  data() {
    return {
      rule: [],
    };
  },

  mounted() {
    this.getFormRule('dms_cost_pool_scale_form');
  },

  methods: {
    setRule(v) {
      const rowData = v;
      rowData.props = {
        ...rowData.props,
        disabled: this.disabledFunc(rowData),
      };
      if (rowData.field === 'rate') {
        rowData.children = [{
          type: 'span',
          slot: 'suffix',
          children: ['%'],
        }];
      }
      if (rowData.field === 'demension') {
        rowData.on = {
          ...rowData.on,
          change: (val) => this.changeDemension(val, rowData.options),
          optionCallback: this.optionCallback,
        };
      }
      if (rowData.field === 'cusName') {
        rowData.props = {
          ...rowData.props,
          paramsObj: {
            functionCode: 'dms_check_customer',
            data: [],
            idKey: 'cusCode',
          },
          placeholder: '客户名称',
          infoKey: 'customerName',
        };
        rowData.on = {
          ...rowData.on,
          setCheckInfo: (val) => this.setCheckInfo(val, 'cus', 'customer'),
        };
      }
      if (rowData.field === 'orgName') {
        rowData.props = {
          ...rowData.props,
          paramsObj: {
            functionCode: 'dms_check_org',
            data: [],
            idKey: 'orgCode',
          },
          placeholder: '组织名称',
          infoKey: 'orgName',
        };
        rowData.on = {
          ...rowData.on,
          setCheckInfo: (val) => this.setCheckInfo(val, 'org'),
        };
      }
      if (rowData.field === 'productName') {
        rowData.props = {
          ...rowData.props,
          paramsObj: {
            functionCode: 'dms_check_product',
            data: [],
            idKey: 'productCode',
          },
          placeholder: '商品名称',
          infoKey: 'productName',
        };
        rowData.on = {
          ...rowData.on,
          setCheckInfo: (val) => this.setCheckInfo(val, 'product'),
        };
      }
      if (rowData.field === 'productLevelName') {
        rowData.props = {
          ...rowData.props,
          paramsObj: {
            functionCode: 'dms_check_product_level',
            data: [],
            idKey: 'productLevelCode',
          },
          placeholder: '商品层级名称',
          infoKey: 'productLevelName',
        };
        rowData.on = {
          ...rowData.on,
          setCheckInfo: (val) => this.setCheckInfo(val, 'productLevel'),
        };
      }
      return rowData;
    },
    formComplete() {
      this.hiddenFields(true, ['cusCode', 'cusName', 'orgCode', 'orgName', 'productName', 'productLevelName']);
      if (this.formConfig.id) {
        this.getData();
      }
    },
    setCheckInfo(val, name, code) {
      this.setValue({
        [`${name}Code`]: val[0][`${code || name}Code`],
        [`${name}Name`]: val[0][`${code || name}Name`],
      });
    },
    optionCallback(options, val) {
      if (val) {
        this.changeDemension(val, options, true);
      }
    },
    // 切换维度
    changeDemension(val, options, init) {
      if (!init) {
        this.hiddenFields(true, ['cusCode', 'cusName', 'orgCode', 'orgName', 'productName', 'productLevelName']);
        this.setValue({
          cusCode: '',
          cusName: '',
          orgCode: '',
          orgName: '',
          productName: '',
          productCode: '',
          productLevelName: '',
          productLevelCode: '',
        });
      }
      const demension = options.find((v) => v.value === val);
      const fieldList = [];
      this.rule.forEach((v) => {
        if (v.title) {
          const name = v.title.split('名称')[0];
          const code = v.title.split('编码')[0];
          demension.label.split('+').forEach((a) => {
            if (name === a) {
              fieldList.push(v.field);
            }
            if (code === a) {
              fieldList.push(v.field);
            }
          });
        }
      });
      this.hiddenFields(false, fieldList);
    },
    disabledFunc(data) {
      let isDisabled = false;
      switch (data.field) {
        case 'cusCode': isDisabled = true; break;
        case 'orgCode': isDisabled = true; break;
        default:
          break;
      }
      // console.log(isDisabled);
      return isDisabled;
    },
    getData() {
      request.get(`/dms/m/orderfeerate/findById/${this.formConfig.id}`).then((res) => {
        if (res.code === 200) {
          this.setValue({
            ...res.result,
          });
        } else {
          this.$message.error(res.messge);
        }
      });
    },
    submit() {
      let path = '/dms/m/orderfeerate/add';
      if (this.formConfig.id) {
        path = '/dms/m/orderfeerate/edit';
      }
      const params = this.getFormData();
      request.post(path, params).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功！');
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.messge);
        }
      });
    },
  },
};
</script>
