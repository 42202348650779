<script>
import TablePage from '@/found/components/table_page';
import Form from '../form/form.vue';

export default {
  extends: TablePage,
  components: {
    // Modal,
    Form,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('dms_cost_pool_scale_table');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.openModal();
      } else if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig.id = row.id;
        this.openModal();
      }
    },
  },
};
</script>
