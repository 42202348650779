<template>
  <div class="home">
    <TablePage />
  </div>
</template>

<script>
import TablePage from './table';

export default {
  name: 'CostPoolScale',
  components: {
    TablePage,
  },
};
</script>
